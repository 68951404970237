import React, { useCallback, useEffect, useState } from "react";
import TelegramText from "../Text/TelegramText";
import { FaCalendarDays, FaUserAstronaut, FaUserCheck } from "react-icons/fa6";
import "./LoginCard.css";
import TelegramInput from "../Input/TelegramInput";
import TelegramButton from "../Button/TelegramButton";
import { getInitializationStatus, loginToServer } from "../../../db/db";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  PATH_ORDER_MANAGEMENT,
  PATH_PRODUCT_TYPE,
} from "../../../constants/Paths";
import {
  updateAgent,
  updateCurrentTimeSlotOrders,
  updateDeliveryChargeList,
  updateDeliveryTimeSlot,
  updateExclusivePromotionChargeList,
  updateProductTypeList,
  updateSessionToken,
  updateSurchargeChargeList,
  updateUserProfile,
} from "../Redux/Actions";
import Loader from "../Loader/Loader";
import { useAlert } from "../../providers/AlertProvider";
import Avocado from "../../../images/avocado.png";
import { useModal } from "../../providers/ModalProvider";
// import TelegramInputDropdownList from "../InputDropdownList/TelegramInputDropdownList";

function LoginCard({
  userId,
  webApp,
  executeMethod,
  isAuthenticated,
  authAgent,
  storeActivity,
  onLogoutClick,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { agentKey: authAgentKey, agentCode } = authAgent;
  const [isLoading, setIsLoading] = useState(false); // State to track loading state
  const [agentKey, setAgentKey] = useState(authAgentKey ? authAgentKey : "");

  const [buttonInput, setButtonInput] = useState("🚀");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [shopClosed, setShopClosed] = useState(false);
  const { triggerAlert } = useAlert();
  const { triggerModal } = useModal();
  const [modalInput, setModalInput] = useState("10");
  // const [loginTitle, setLoginTitle] = useState("WE ARE OPEN");

  const updateModalInput = useCallback((e) => {
    const newValue = Number(e.target.value);
    setModalInput(newValue);
  }, []);

  const onMainClick = useCallback(async () => {
    // Make an API call to validate the access key
    try {
      // let cookies = document.cookie;
      // let cookieExists = cookies.some((cookie) => {
      //   let [name] = cookie.trim().split("=");
      //   return name === cookieName;
      // });
      // triggerAlert("Cookies", JSON.stringify(cookies));

      // Modal Testing¡
      // alert("Here");
      // triggerModal(
      //   1,
      //   "Stay in touch",
      //   "This is a dummy newsletter form so don't bother trying to test it. Not that I expect you to, anyways. :)",
      //   modalInput,
      //   "Submit",
      //   (e) => {
      //     updateModalInput(e);
      //   },
      //   onMainClick
      // );
      // return;

      // Show loading popup and disable user interaction
      setIsLoading(true);
      setButtonDisabled(true);
      setButtonInput("Verifying..");
      if (!agentKey) {
        // throw new Error("Access Key cannot be blank");
        triggerAlert("Store", "Access Key cannot be blank.");
        return;
      }
      if (userId) {
        const loginDetails = await loginToServer(userId, agentKey);

        if (!loginDetails) {
          triggerAlert(
            "Invalid / Expired Key",
            "Please reach out to your agent."
          );
          return;
        }

        const { token, agent, user } = loginDetails;

        if (!agent) {
          triggerAlert(
            "Failed to login properly.",
            "Please reach out to your agent."
          );
          return;
        }

        if (!user) {
          triggerAlert(
            "Failed to login actually.",
            "Please reach out to your agent."
          );
          return;
        }

        if (!token) {
          triggerAlert(
            "Failed to login subsequently.",
            "Please reach out to your agent."
          );
          return;
        }

        const agentId = agent.id;

        const {
          deliveryTimeSlots,
          chargeList,
          productTypes,
          currentTimeSlotOrders,
          storeActivity,
        } = await getInitializationStatus(token, agentId, userId);

        if (storeActivity && !deliveryTimeSlots) {
          const openingDate = storeActivity.openingDate;

          // const date = new Date(openingDate);
          // const options = { day: "numeric", month: "short", year: "numeric" };
          // const formattedOpeningDate = date.toLocaleDateString(
          //   "en-US",
          //   options
          // );

          // setLoginTitle("WE ARE CLOSED");

          throw new Error(
            `\nThe store is closed.\nWe are open after ${openingDate}`
          );
        }

        if (currentTimeSlotOrders) {
          dispatch(updateCurrentTimeSlotOrders(currentTimeSlotOrders));
        }

        if (deliveryTimeSlots) {
          dispatch(updateDeliveryTimeSlot(deliveryTimeSlots));
        }

        if (chargeList && chargeList.length > 0) {
          const deliveryCharge = chargeList.filter(
            (item) => item.type.toUpperCase() === "DELIVERY"
          );

          const surchargeCharge = chargeList.filter(
            (item) => item.type.toUpperCase() === "SURCHARGE"
          );

          const exclusivePromotionCharge = chargeList.filter(
            (item) => item.type.toUpperCase() === "EXCLUSIVEPROMOTION"
          );

          // const concurrentPromotionCharge = chargeList.filter(
          //   (item) => item.type.toUpperCase() === "CONCURRENTPROMOTION"
          // );

          if (deliveryCharge) {
            dispatch(updateDeliveryChargeList(deliveryCharge));
          }

          if (surchargeCharge) {
            dispatch(updateSurchargeChargeList(surchargeCharge));
          }

          if (exclusivePromotionCharge) {
            dispatch(
              updateExclusivePromotionChargeList(exclusivePromotionCharge)
            );
          }

          // if (concurrentPromotionCharge) {
          //   dispatch(
          //     updateConcurrentPromotionChargeList(concurrentPromotionCharge)
          //   );
          // }
        }

        dispatch(updateSessionToken(token));
        dispatch(updateUserProfile(user));

        dispatch(updateAgent(agent));

        if (!productTypes) {
          triggerAlert("V", "Shop is closed today.");
          return;
          // throw new Error("Shop is closed.");
        }
        const mappedProductTypes = productTypes.map((productType) => ({
          id: productType.id,
          title: productType.type,
          descr: productType.type,
        }));

        dispatch(updateProductTypeList(mappedProductTypes));

        if (currentTimeSlotOrders) {
          navigate(PATH_ORDER_MANAGEMENT);
        } else {
          navigate(PATH_PRODUCT_TYPE);
        }
      }
    } catch (error) {
      triggerAlert("Avocado", error.message);
      // alert(error);
      // Handle any error that occurred during the API call
      // Display an error message or take appropriate action
    } finally {
      setTimeout(() => {
        setButtonInput("Proceed");
        // Enable the button after the specified timeout
        setButtonDisabled(false);
      }, 500); // 3000 milliseconds = 3 seconds
      // Show loading popup and disable user interaction
      setIsLoading(false);
    }
  }, [
    agentKey,
    dispatch,
    navigate,
    userId,
    triggerAlert,
    modalInput,
    triggerModal,
    updateModalInput,
  ]);

  useEffect(() => {
    webApp.ready();
    webApp.MainButton.onClick(onMainClick);
    webApp.enableClosingConfirmation();

    webApp.MainButton.text = "VIEW ORDER";
    webApp.MainButton.color = "#31b545";

    webApp.MainButton.hide();

    if (authAgentKey) {
      setAgentKey(authAgentKey);
    }

    if (storeActivity) {
      if (storeActivity.status === "CLOSED") {
        setShopClosed(true);
        setButtonDisabled(true);
        // setLoginTitle("WE ARE CLOSED");
        // throw new Error("The store is closed.");
      }
    }

    if (!webApp.isExpanded) {
      executeMethod("expand", webApp.expand, true);
    }

    return () => {
      webApp.MainButton.offClick(onMainClick);
    };
  }, [webApp, onMainClick, executeMethod, authAgentKey, isAuthenticated]);

  return (
    <div className="LoginCard">
      {!isLoading ? (
        <div className="login__card__container">
          <div className="login__card__content__container">
            {Object.keys(storeActivity).length > 0 ? (
              <>
                <TelegramText className="telegramTitle">
                  {storeActivity.status}
                </TelegramText>
                <TelegramText className="center__items telegramSubtitle">
                  We will be back after
                  <br />
                  {storeActivity.openingDate}
                </TelegramText>
              </>
            ) : (
              <>
                <TelegramText className="telegramTitle">
                  WE ARE OPEN
                </TelegramText>
                <TelegramText className="center__items telegramSubtitle">
                  Shop with us Mon-Sat!
                </TelegramText>
              </>
            )}

            {!isAuthenticated ? (
              <div className="unauth-access-container">
                <TelegramInput
                  className={`unauth-input`}
                  type="password"
                  // placeholder="Agent Key"
                  placeholder={`${buttonDisabled ? "CLOSED" : "Avocado"}`}
                  value={agentKey}
                  // value={agentKey}
                  onChange={(e) => setAgentKey(e.target.value)}
                  disabled={buttonDisabled}
                />
                {/* <TelegramInputDropdownList
                  className="unauth-input"
                  type="password"
                  // placeholder="Agent Key"
                  placeholder="Avocado"
                  value={agentKey}
                  onChange={(e) => setAgentKey(e.target.value)}
                /> */}
                {/* <TelegramText className="sub-text align-bottom">
            Paste your agent key above
          </TelegramText> */}
                <TelegramButton
                  className={`gradient-button small-font`}
                  onClick={onMainClick}
                  disabled={buttonDisabled}
                >
                  {buttonInput}
                </TelegramButton>
                {/* <TelegramText className="login-text telegramSubtitle">
                  <FaUserCheck /> Avocado Check.
                </TelegramText> */}
                <TelegramText className="login-text telegramSubtitle">
                  <FaCalendarDays /> Sun orders delivered on Mon.
                </TelegramText>
              </div>
            ) : (
              <div className="auth-access-container">
                <div className="auth-agent-change-container">
                  <TelegramButton
                    className={`align-bottom auth-agent-change-button`}
                    onClick={onLogoutClick}
                    disabled={buttonDisabled}
                  >
                    Avocado Change
                  </TelegramButton>
                </div>
                <TelegramButton
                  className={`gradient-button small-font`}
                  onClick={onMainClick}
                  disabled={buttonDisabled}
                >
                  {storeActivity.status === "CLOSED"
                    ? "CLOSED 🔒"
                    : `Avocado with ${agentCode}`}
                </TelegramButton>
                {/* <TelegramText className="login-text telegramSubtitle small-font">
                  <FaUserAstronaut /> Avocado Approved.
                </TelegramText> */}
                <TelegramText className="login-text telegramSubtitle">
                  <FaCalendarDays /> Sun orders delivered on Mon.
                </TelegramText>
              </div>
            )}
          </div>
          <div className="login__card__image__container">
            <img src={Avocado} alt="Avocado" />
          </div>
        </div>
      ) : (
        <Loader title="Retrieving..." />
      )}
    </div>
  );
}
export default LoginCard;
